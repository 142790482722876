import React, { useState } from "react";
import api from "../../services/api";

const AddUser = () => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false); // Loading state for the form
  const [message, setMessage] = useState(""); // Message to show status (success/error)

  // Helper function to validate form inputs
  const validateForm = () => {
    if (userName.trim().length < 3) {
      return "User name must be at least 3 characters long.";
    }
    if (password.trim().length < 6) {
      return "Password must be at least 6 characters long.";
    }
    return null; // No validation errors
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage(""); // Clear previous messages

    // Validate inputs
    const validationError = validateForm();
    if (validationError) {
      setMessage(validationError);
      setLoading(false);
      return;
    }

    try {
      // API call to add user
      await api.post("/add_user", { user_name: userName, password });
      setMessage("User added successfully!");
      setUserName("");
      setPassword("");
    } catch (error) {
      console.error("Error adding user:", error);
      const errorMessage =
        error.response?.data?.error || "Failed to add user. Please try again.";
      setMessage(errorMessage);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  return (
    <div style={{ maxWidth: "400px", margin: "0 auto" }}>
      <h2>Add User</h2>
      <form onSubmit={handleSubmit}>
        <div style={{ marginBottom: "15px" }}>
          <label htmlFor="userName">User Name:</label>
          <input
            type="text"
            id="userName"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Enter user name"
            required
            style={{ width: "100%", padding: "8px", marginTop: "5px" }}
            aria-label="Enter user name"
          />
        </div>
        <div style={{ marginBottom: "15px" }}>
          <label htmlFor="password">Password:</label>
          <input
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            required
            style={{ width: "100%", padding: "8px", marginTop: "5px" }}
            aria-label="Enter password"
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          style={{
            width: "100%",
            padding: "10px",
            backgroundColor: loading ? "#ccc" : "#007BFF",
            color: "#fff",
            border: "none",
            cursor: loading ? "not-allowed" : "pointer",
          }}
        >
          {loading ? "Adding User..." : "Add User"}
        </button>
      </form>
      {message && (
        <p
          aria-live="polite"
          style={{
            marginTop: "15px",
            color: message.includes("success") ? "green" : "red",
          }}
        >
          {message}
        </p>
      )}
    </div>
  );
};

export default AddUser;
