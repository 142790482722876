// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ListUsers from "./components/UserManagement/ListUsers";
import AddUser from "./components/UserManagement/AddUser";

const App = () => {
  return (
    <Router>
      <div>
        <nav>
          <a href="/users">User List</a> | <a href="/add-user">Add User</a>
        </nav>
        <Routes>
          <Route path="/users" element={<ListUsers />} />
          <Route path="/add-user" element={<AddUser />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
